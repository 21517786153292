import React from 'react';
import { Link } from 'gatsby';

function CallToAction(props) {
    return (
        <div className="call-to-action-wrapper call-to-action text-white-wrapper ptb--120">
            <div className="container pt-4 pt-md-0 pb-4 pb-md-0">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner text-center">
                            <h2>Pripravljeni na doživetje edinstvenega litijskega podzemlja? </h2>
                            <p className="cta-description mt--60 mb-0 font-weight-normal text-white">Ogledi možni vse dni v tednu po predhodni najavi in za minimalno štiri obiskovalce.</p>
                            <p className="cta-description mb-0 font-weight-normal text-white">Brez najave si lahko ogledate Izvozni rov vsako soboto ob 10:00.</p>
                            <Link className="btn-default btn-border btn-large btn-white mt--40" to="/cenik">Ogled cenika</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CallToAction;